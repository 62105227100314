import React, { useState } from 'react';
import Button from '../../../Button';
import SortIcon from '../../../../public/static/images/new_search/icons/icon-sort.svg';
import Icon from '../../../Icon';
import FilterModal from '../../common/FilterModal';

export const Sorting = () => {
  const [modalConfig, setModalConfig] = useState();

  const hideModal = () => setModalConfig(null);

  return (
    <>
      <Button
        cypress="open-sorting-modal"
        additionalClass="open-sorting-modal"
        onClickHandler={() => setModalConfig('sorting')}
        stat="new-search-sorting-modal"
      >
        <Icon IconComponent={SortIcon} width={21} height={18} indent={false} />
      </Button>

      <FilterModal
        hide={hideModal}
        isOpen={!!modalConfig}
        modalConfig={modalConfig}
      />
    </>
  );
};
