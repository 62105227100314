import React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import Icon from '../../../Icon';
import DeleteIcon from '../../../../public/static/images/new_search/icons/icon-delete.svg';

const NumberField = ({ name, placeholder, id, stat }) => {
  const { change } = useForm();
  const { values } = useFormState();

  const resetValue = () => {
    change(name, undefined);
  };

  const noLeadingZeroNumber = (value = '') => {
    if (
      typeof value === 'string' &&
      value.startsWith('0') &&
      value.length > 1
    ) {
      return value.replace(/^0/, '').replace(/\D/g, '');
    }

    return value;
  };
  const onlyNums = (value = '') => {
    return typeof value === 'string' ? value.replace(/\D/g, '') : value;
  };

  return (
    <div className="filters__field-wrapper">
      <Field
        id={id}
        name={name}
        data-stat={stat}
        pattern="[0-9]*"
        component="input"
        autoComplete="off"
        inputMode="numeric"
        maxLength="9"
        parse={onlyNums}
        format={noLeadingZeroNumber}
        placeholder={placeholder}
        className="filters__text-field"
      />
      <div
        tabIndex={0}
        role="button"
        onClick={resetValue}
        onKeyPress={resetValue}
        className="filters__delete-icon"
        style={{ visibility: values[name] ? 'visible' : 'hidden' }}
      >
        <Icon width={15} height={15} IconComponent={DeleteIcon} />
      </div>
    </div>
  );
};

NumberField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  stat: PropTypes.string,
  placeholder: PropTypes.string
};

export default NumberField;
