import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { setSuffix } from '../../../utils/helpers';
import NewSearchLeased from '../NewSearchFilters/components/NewSearchLeased';
import { Sorting } from './Sorting/Sorting';

const categoryMap = {
  1: {
    buy: 'new_search.flat0',
    rent: 'new_search.flat0',
    paid_daily: 'new_search.flat'
  },
  2: {
    buy: 'new_search.flat0',
    rent: 'new_search.flat0',
    paid_daily: 'new_search.flat'
  },
  3: {
    buy: 'new_search.flat0',
    rent: 'new_search.flat0',
    paid_daily: 'new_search.flat'
  },
  5: {
    buy: 'new_search.houseVilla0',
    rent: 'new_search.houseVilla0',
    paid_daily: 'new_search.houseVilla'
  },
  7: {
    buy: 'new_search.office',
    rent: 'new_search.office',
    paid_daily: 'new_search.office'
  },
  8: {
    buy: 'new_search.garage',
    rent: 'new_search.garage',
    paid_daily: 'new_search.garage'
  },
  9: {
    buy: 'new_search.land',
    rent: 'new_search.land',
    paid_daily: 'new_search.land'
  },
  10: {
    buy: 'new_search.object',
    rent: 'new_search.object',
    paid_daily: 'new_search.object'
  }
};

const titleMap = {
  buy: 'new_search.purchase',
  rent: 'new_search.lease',
  paid_daily: 'search.form.paid_daily'
};

const SearchTypeHeader = ({
  values,
  totalCount,
  handleBack,
  searchType,
  hasCategory
}) => {
  const { t, i18n } = useTranslation();
  const categoryId = values.category_id;
  const category = categoryMap[categoryId]?.[searchType] || '';
  const title = titleMap[searchType];

  useEffect(() => {
    window.addEventListener('popstate', handleBack);

    return () => window.removeEventListener('popstate', handleBack);
  }, []);

  return (
    <div className="new-search-popup__wrapper">
      <div className="new-search-popup__header new-search-popup__no-border">
        <div className="new-search-popup__header-action">
          <button
            className="new-search-popup__header-btn new-search-popup__header-btn--close"
            onClick={handleBack}
          />
        </div>
        <h2
          className="new-search-popup__header-title"
          data-cy="new-search-title"
        >
          {`${t(title)}${hasCategory ? t(category) : ''}`}
          {
            <p className="new-search-popup__header-subtitle">
              {totalCount ? (
                t(setSuffix(i18n, totalCount, 'ad'), {
                  count: totalCount
                })
              ) : (
                <span className="placeholder-subtitle animation" />
              )}
            </p>
          }
        </h2>
        <Sorting />
      </div>
      {hasCategory && (
        <NewSearchLeased
          t={t}
          margin="70px 0 10px"
          value={values?.leased}
          submitOnChange={true}
        />
      )}
    </div>
  );
};

SearchTypeHeader.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  values: PropTypes.object,
  handleBack: PropTypes.func,
  totalCount: PropTypes.number,
  hasCategory: PropTypes.bool,
  searchType: PropTypes.oneOf(['buy', 'rent', 'paid_daily'])
};

export default SearchTypeHeader;
